<template>
  <div class="cantainer">
    <div class="first">
      <h4>{{ $t("help.title1") }}</h4>
      <p>{{ $t("help.content1") }}</p>
      <el-image
        src="https://holodata.prometh.xyz/webMetaTrade/first.png"
        lazy
      ></el-image>
    </div>
    <div class="first">
      <h4>{{ $t("help.title2") }}</h4>
      <p>{{ $t("help.content2") }}</p>
      <el-image
        src="https://holodata.prometh.xyz/webMetaTrade/second.png"
        lazy
      ></el-image>
    </div>
    <div class="first">
      <h4>{{ $t("help.title3") }}</h4>
      <p>{{ $t("help.content3") }}</p>
      <el-image
        src="https://holodata.prometh.xyz/webMetaTrade/thirdly.png"
        lazy
      ></el-image>
    </div>
    <div class="first">
      <h4>{{ $t("help.title4") }}</h4>
      <p>{{ $t("help.content4") }}</p>
      <el-image
        src="https://holodata.prometh.xyz/webMetaTrade/fourthly.png"
        lazy
      ></el-image>
    </div>
    <div class="first">
      <p>{{ $t("help.content5") }}</p>
      <el-image
        src="https://holodata.prometh.xyz/webMetaTrade/fifth.png"
        lazy
      ></el-image>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.cantainer {
  height: 100%;
  padding: 50px 100px 100px;
  overflow: auto;
  .first {
    width: 100%;
  }
}
</style>
